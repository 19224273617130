.container {
  background: white;
  margin-top: 10vh;
  min-height: 30vh;
  padding: 0;

  @media (--medium) {
    margin-top: 0;
  }

  &.white {
    color: white !important;
  }

  &.black {
    color: black !important;
  }

  &.night-rider {
    color: var(--color-night-rider) !important;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0 2.5vw;

  @media (--xsmall) {
    justify-content: center;
  }

  @media (--medium) {
    flex-direction: row;
  }
}

.logo {
  flex: 1 1 20%;
  margin: 0 auto 16px;
  order: 0;
  text-align: center;

  @media (--medium) {
    margin: 16px 0 0 0;
  }

  @media (--large) {
    flex: 1 1 10%;
    height: 234px;
    margin: 16px 5vw 0 0;
    text-align: left;
    width: 280px;
  }
}

.logoImage {
  height: 32px;
  width: 80px;
  @media (--large) {
    height: 36px;
    width: 64px;
  }

  &:hover {
    filter: invert(67%) sepia(35%) saturate(5141%) hue-rotate(321deg) brightness(100%)
      contrast(102%);
    opacity: 1 !important;
  }
}

.newsletterContainer {
  background: #fff7ef;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 16px;
  width: 100%;
  @media (--medium) {
    align-items: center;
    flex-direction: row;
    padding: 24px 168px;
  }
}

.newsletterInput {
  @media (--large) {
    width: 500px;
  }

  &.black {
    border-bottom: 1px solid black;
  }

  &.white {
    border-bottom: 1px solid white;
  }

  &.night-rider {
    border-bottom: 1px solid var(--color-night-rider);
  }
}

.newsletterTitle {
  margin-bottom: 20px;
  text-align: center;

  @media (--medium) {
    margin-bottom: 10px;
    text-align: left;
  }
}

.emailInput {
  background: transparent;
  font-family: var(--font-termina);
  font-size: 18px;
  line-height: 150%;
  padding: 0 5px 0 0;
  width: 65%;

  @media (--xsmall) {
    width: 80%;
  }

  @media (--large) {
    padding: 0 20px;
    width: 80%;
  }

  &::placeholder {
    font-family: var(--font-termina);
    font-size: 18px;
    line-height: 150%;
  }

  &:disabled {
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }

  &.black::placeholder {
    color: black;
  }

  &.white::placeholder {
    color: white;
  }

  &.night-rider::placeholder {
    color: var(--color-night-rider);
  }
}

.submitButton {
  border: none;
  bottom: -5px;
  float: right;
  position: relative;

  &:focus {
    outline: none;
  }
}

.success {
  animation: fadeIn 0.5s;
  @media (--medium) {
    padding: 5px 20px;
  }
}

.error {
  color: var(--color-red);
  @media (--medium) {
    padding: 5px 20px;
  }
}

.paymentMethodText {
  margin: 20px 0 10px;

  @media (--medium) {
    margin: 40px 0 10px;
  }
}

.paymentIconContainer {
  display: flex;
  flex-flow: row wrap;
}

.paymentIcon {
  background: white;
  display: inline-block;
  height: 24px;
  margin-bottom: 10px;
  margin-right: 10px;
  width: 40px;

  &:last-child {
    margin-right: 0;
  }
}

.link {
  flex: 1 1 40%;
  margin: 10px 0;
  padding: 0;
  text-align: left;

  @media (--medium) {
    gap: 0;
    margin: 10px 0;
    padding: 0;
    text-align: initial;
  }

  &.hide {
    display: none;

    @media (--medium) {
      display: initial;
    }
  }
}

.hoverLink:hover {
  color: #ff6f61;
  opacity: 1 !important;
}

.companyName {
  color: #a4a4a4;
  margin-top: 32px;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.title {
  font-family: var(--font-brown);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.officeTitle {
  font-family: var(--font-brown);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  text-align: center;
  @media (--medium) {
    text-align: left;
  }
}

.officeAddress {
  font-family: var(--font-brown);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.addressContainer {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 119px;
  margin-top: 20px;
  width: 280px;

  @media (--large) {
    align-items: flex-start;
  }

  &:hover {
    color: var(--color-primary);
    opacity: 1 !important;
  }
}

.linkContainer {
  align-items: flex-start;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  gap: 0px 20px;
  padding: 0 20px;
  width: 98vw;
  @media (--xxsmall) {
    justify-content: flex-start;
  }
  @media (--medium) {
    gap: 0 56px;
    justify-content: flex-end;
    padding: 0;
    width: 684px;
  }
}

.linkTitle {
  padding: 8px 0 5px 0;
}

.joinBaseTitle {
  padding: 7px 0 2px 0;
  @media (--medium) {
    padding: 20px 0 2px 0;
  }
}

.iconSocialContainer {
  display: flex;
  gap: 12px;
  justify-content: space-around;
  margin-top: 5px;
  @media (--medium) {
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.icon {
  &:hover {
    filter: invert(67%) sepia(35%) saturate(5141%) hue-rotate(321deg) brightness(100%)
      contrast(102%);
    opacity: 1 !important;
  }
}

.findUsContainer {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  text-align: center;
}

.ecommerceLogoContainer {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin-top: 16px;
}

.footerContainer {
  margin: 0 auto;
  padding: 40px 0;

  @media (--xxlarge) {
    max-width: 2000px;
  }
}

.copyrightContainer {
  align-items: center;
  border-top: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 24px;
  width: 100%;
}

.formContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contactUsContainer {
  margin: 0 auto;
  width: 95vw;
  @media (--medium) {
    margin: 0;
    width: auto;
  }
}

.contactUsLinkContainer {
  display: flex;
  flex-direction: row;
  gap: 60px;
  justify-content: flex-start;
  margin: 10px 0;
  @media (--medium) {
    flex-direction: column;
    gap: 10px;
    margin: 0;
  }
}

.linkSocialTitle {
  margin: 20px 0;
  text-align: center;
  @media (--medium) {
    margin: 30px 0 10px 0;
    text-align: left;
  }
}

.sublinkContainer {
  max-width: 180px;
  min-width: 140px;
  @media (--medium) {
    max-width: none;
    min-width: none;
  }
}
