.container {
  position: relative;
}

.inputContainer {
  align-items: center;
  background: white;
  border: 1px solid var(--color-grey-alpha-02);
  box-shadow: 0 2px 50px -2px var(--color-grey-alpha-02);
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-height: 55px;
  position: relative;

  &.inputFocus {
    border: 1px solid var(--color-primary);
  }

  &.inputError {
    border: 2px solid var(--color-primary-dark);
  }

  &.inputDisabled {
    background: var(--color-grey-02);
    border: 1px solid var(--color-grey-02);
  }

  /* VARIANTS */
  &.clearbox {
    background: transparent;
    border: none;
    border-bottom: 1px solid grey;
    border-radius: 0;
    box-shadow: none;

    &.inputFocus {
      border-bottom: 1px solid var(--color-primary);
    }

    &.inputError {
      border-bottom: 1px solid var(--color-primary-dark);
    }

    &.inputDisabled {
      background: var(--color-grey-02);
      border-bottom: 1px solid grey;
    }

    /* SPECIAL CONDITION FOR CLEARBOX TEXTAREA */
    &.textAreaContainer {
      border: 1px solid grey;

      &.inputFocus {
        border: 1px solid var(--color-primary);
      }

      &.inputError {
        border: 1px solid var(--color-primary-dark);
      }

      &.inputDisabled {
        background: var(--color-grey-02);
        border: 1px solid grey;
      }
    }
  }

  &.blackBorder {
    border: 2px solid black;
  }

  &.primaryBorder {
    border: 2px solid #FBD7CA;

    &:focus{
      border: 2px solid var(--color-primary);
    }
  }
}

.inputIcon {
  align-items: center;
  display: flex;
  flex: 0 0 18px;
  padding: 0 10px;
}

.textarea {
  border: none;
  height: 150px;
  outline: none;
  resize: none;
}

.input,
.textarea {
  background: transparent;
  flex: 1 0 auto;
  font-family: var(--font-brown);
  font-size: 14px;
  line-height: 16px;
  max-width: 100%;
  padding: 12px;

  @media (--medium) {
    font-size: 18px;
    line-height: 20px;
    padding: 16px;
  }

  &.hasIcon {
    padding: 10px 22px 10px 10px;

    @media (--medium) {
      padding: 16px 32px 16px 10px;
    }
  }

  &:focus {
    border: none;
    outline: none;
  }

  &::placeholder {
    opacity: 0.5;
  }

  /* VARIANTS */
  &.clearbox {
    padding: 10px;

    @media (--medium) {
      padding: 6px;
    }
  }
}

.clearIcon {
  cursor: pointer;
  display: block;
  height: 18px;
  position: absolute;
  right: 10px;
  width: 18px;
}

.commonIcon {
  cursor: pointer;
  display: block;
  height: 20px;
  position: absolute;
  right: 10px;
  width: 20px;

  @media(--medium) {
    right: 20px;
  }
}

.rightIcon {
  display: block;
  height: 45px;
  position: absolute;
  right: 10px;
  width: 45px;
}

.errorText {
  color: var(--color-primary-dark);
  font-family: var(--font-brown);
  font-size: 11px;
  height: 20px;
  margin-top: 5px;

  @media (--medium) {
    font-size: 14px;
  }
}
